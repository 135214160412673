const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-west-1:19e14bf4-47f4-4d5f-97dc-08fc9ce406c3', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'eu-west-1', // REQUIRED - Amazon Cognito Region
    //userPoolId: 'XX-XXXX-X_abcd1234', // OPTIONAL - Amazon Cognito User Pool ID for authenticated user access
    //userPoolWebClientId: 'XX-XXXX-X_abcd1234', // OPTIONAL - Amazon Cognito Web Client ID for authenticated user access
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          "asset-tracking-map": { // REQUIRED - Amazon Location Service Map resource name
            style: "VectorEsriStreets", // REQUIRED - String representing the style of map resource
          },
        },
        default: "asset-tracking-map", // REQUIRED - Amazon Location Service Map resource name to set as default
      },
      search_indices: {
        items: ["test-location"], // REQUIRED - Amazon Location Service Place Index name
        default: "test-location", // REQUIRED - Amazon Location Service Place Index name to set as default
      },
      geofenceCollections: {
          items: ["TestGeofenceCollection"], // REQUIRED - Amazon Location Service Geofence Collection name
          default: "TestGeofenceCollection", // REQUIRED - Amazon Location Service Geofence Collection name to set as default
      },
      region: 'eu-west-1', // REQUIRED - Amazon Location Service Region
    },
  },
  Storage: {
    AWSS3: {
        bucket: 'geo-files-bucket', //REQUIRED -  Amazon S3 bucket name
        region: 'eu-west-1', //OPTIONAL -  Amazon service region
    }
  }

  ///// To get started with this: https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/#manual-setup-import-storage-bucket
};

  


export default amplifyConfig;