import React, { useRef, useState } from "react";
import Iframe from 'react-iframe';
import { Storage } from "@aws-amplify/storage";
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Icon,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";

export default function UploadGeofences() {
  const ref = useRef();
  return (
    <ContentLayout header={<Header variant="h1">Operational metrics</Header>}>
      <Container header={<Header variant="h2">Geofences</Header>}>
      <Iframe url="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=IoT&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTc0NTQ4MTMxNjA0MSIsIlUiOiJ1cy1lYXN0LTFfcDZHY29YQlNkIiwiQyI6IjRkZW9hZnZuaGRpbGdvOGpsMmc4cWI0MTc0IiwiSSI6InVzLWVhc3QtMTo3NjVlZmJiZi0xZWJjLTQwODAtOTQ5NC1iN2YwNDhkNGFjZWMiLCJNIjoiUHVibGljIn0%3D"
        id=""
        width="100%"
        height="1000px"
        className=""/>
      </Container>
    </ContentLayout>
    );
};
