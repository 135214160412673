import React, { useState } from "react";
import { Heading, Text } from "@aws-amplify/ui-react";
import { Marker, Popup } from "react-map-gl";


export function MarkerWithPopup(props) {
  const key = props.index;
  const marker = props.marker;
  const markerTime =  Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(marker.sample_time));
  const [showPopup, setShowPopup] = useState(false);

  const handleMarkerClick = ({ originalEvent }) => {
    originalEvent.stopPropagation();
    setShowPopup(true);
  };

  return (
    <>
      <Marker
        key={key}
        latitude={marker.latitude}
        longitude={marker.longitude}
        color={props.color}
        onClick={handleMarkerClick}
      />
      {showPopup && (
        <Popup
          key={"popup" + key}
          latitude={marker.latitude}
          longitude={marker.longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
        >
          <Heading key={"heading" + key } level={6}>Id: {marker.device_id}</Heading>
          <Text key={"description" + key }>{marker.description}</Text>
          <Text key={"sample_time" + key }>Sample time: {markerTime}</Text>
        </Popup>
      )}
    </>
  );
}