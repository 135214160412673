
import React, { useEffect, useRef, useState } from "react";
import keyIndex from 'react-key-index';
import { Amplify } from "aws-amplify";
import {
  Button,
  Flex,
  LocationSearch,
  MapView,
} from "@aws-amplify/ui-react";
import awsconfig from "../constants/amplifyConfiguration"; 

import { MarkerSearchBar, MarkerWithPopup } from "../components";
import {
  FetchMarkerHistory,
  FetchMarkersList,
  Geofence,
} from "../api";

import '@aws-amplify/ui-react/styles.css';
import "maplibre-gl/dist/maplibre-gl.css";
import "maplibre-gl-js-amplify/dist/public/amplify-ctrl-geofence.css";


Amplify.configure(awsconfig);


export default function HomeView() {
  const mapRef = useRef();
  // const isInitialMount = useRef(true);
  const [ markersList, setMarkersList ] = useState([]);
  const [ selectedMarker, setSelectedMarker ] = useState([]);
  const [ markerHistory, setMarkerHistory ] = useState([]);

  ///// Initial map view: focus is on Paris
  const { latitude, longitude } = { latitude: 48.858, longitude: 2.295 };

  ///// Call API when loading component
  useEffect(() => {
    FetchMarkersList(setMarkersList);
  }, []);

  ///// Call API every X seconds
  useEffect(() => {
    const interval = setInterval(() => {
      selectedMarker.length === 0
      ? FetchMarkersList(setMarkersList)
      : FetchMarkerHistory(selectedMarker.device_id, setMarkerHistory);
      console.log("SELECTED MARKER: ", selectedMarker);
      console.log("HISTORY: ", markerHistory)}
      , 5000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedMarker, markerHistory]);

  // Focus on the point that has just been selected
  // useEffect(() =>{
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     mapRef.current.flyTo({ center: [selectedMarker.longitude, selectedMarker.latitude], zoom: 11 });
  //     console.log("Selected Marker: ", selectedMarker)
  //   }
  // }, []);

  // console.log("MArker history", markerHistory)
  return (
      <>
        <Flex
          justifyContent="space-between"
          marginTop="0.5%"
          marginBottom="0.5%"
        >
          <MarkerSearchBar
            options={markersList}
            setSelectedMarker={setSelectedMarker}
            setMarkerHistory={setMarkerHistory}
            fetchMarkerHistory={FetchMarkerHistory}
          />
          <Flex
            flex={1}
            alignItems="center"
            border="1px solid"
            borderRadius="6px"
          >
            <Flex flex={1} justifyContent="center" > Selected marker ID:</Flex>
            <Flex flex={1} justifyContent="center" >{selectedMarker.length === 0 ? "No marker selected" : selectedMarker.device_id}</Flex>
          </Flex>
          <Flex flex={1} justifyContent="end" alignItems="center">
            <Button size="small" onClick={() => {

              setSelectedMarker([]);
              setMarkerHistory([]);
              }}
            >
              Unselect marker
            </Button>
          </Flex>
        </Flex>
        <MapView
          style={{width: "100%", height: "90%"}}
          initialViewState={{
            latitude: latitude,
            longitude: longitude,
            zoom: 11,
          }}
          ref={mapRef}
        >
          <Geofence />
          {/* <LocationSearch position="top-right" /> */}
          { selectedMarker.length === 0
            ? markersList.map((marker, index) => {
                return (
                  <MarkerWithPopup key={"marker"+marker.device_id} index={marker.device_id} marker={marker} color={"purple"} />
                );
              })
            : markerHistory.map((marker, index) => {
              return (
                <MarkerWithPopup key={"marker"+marker.device_id} index={marker.device_id + index} marker={marker} color={index === 0 && "red"} />
              );
            })
          }
        </MapView>
      </>
  );
}
