import { SideNavigation } from "@cloudscape-design/components";

const items = [
  { type: "link", text: "Map", href: "/map" },
  { type: "link", text: "Upload Geofences", href: "/upload-geofences"},
  { type: "link", text: "Metrics", href: "/metrics"},
];

export default function Navigation() {
  return (
    <>
      <SideNavigation
        activeHref={window.location.pathname}
        header={{ href: "#/", text: "Services" }}
        items={items}
      />
    </>
  )
}
