import React, { useRef, useState } from "react";

import { Storage } from "@aws-amplify/storage";
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Icon,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";

export default function UploadGeofences() {
  const ref = useRef()
  const [ fileToUpload, setFileToUpload ] = useState();
  const [ uploadStatus, setUploadStatus ] = useState(false);
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ isFlashbarDisplayed, setIsFlashbarDisplayed ] = useState(false);
  const [ flashbarItems, setFlashbarItems ] = useState([
    {
      type: "success",
      content: "The file has been successfully uploaded to your S3 bucket.",
      action: <Button href="/map">Go back to the map</Button>,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setIsFlashbarDisplayed(false),
      id: "message_1"
    }
  ]);
    // To upload files using Amplify

  function handleClick(e) {
    ref.current.click();
  }
  
  function handleFileChange(e) {
    const file = e.target.files[0];
    setFileToUpload(file);
  }

  async function uploadFile() {
    setUploadStatus(true);
    try {
      await Storage.put(fileToUpload.name, fileToUpload, {
        contentType: "file/csv", // contentType is optional

        ///// Folllowing lines: CORS error, when dealing with the S3 bucket /////

        // resumable: true,
        // completeCallback: (event) => {
        //   console.log(`Successfully uploaded ${event.key}`);
        //   setUploadStatus(false);
        //   setFileToUpload();
        // },

        progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setUploadProgress(100*progress.loaded/progress.total);
            if (progress.loaded === progress.total) {
              setIsFlashbarDisplayed(true);
              setUploadStatus(false);
              setUploadProgress(0);
              setFileToUpload();
              return;
            }
        },
        errorCallback: (err) => {
            console.error('Unexpected error while uploading', err);
        }
        });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }


  return (
    <ContentLayout header={<Header variant="h1">Create geofences</Header>}>
      {isFlashbarDisplayed && <Flashbar items={flashbarItems} />}
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {
              uploadStatus
              ? <Button variant="primary" iconName="upload" loading={true}>
                  Uploading file: {uploadProgress.toFixed(0)}%
                </Button>
              : <>
                  <Button variant="link">Cancel</Button>
                  <Button variant="primary" iconName="upload" loading={uploadStatus === "inProgress" && true} onClick={uploadFile}>
                    Upload file
                  </Button>
                </>
            }
          </SpaceBetween>
        }
      >
        <Container header={<Header variant="h2">Geofences</Header>}>
          <FormField
            label="Upload a list a geofences"
            description="Choose a file with all the addresses of the location for which you want to create geofences. This file will be stored on a S3 bucket."
            constraintText="Only CSV format."
            // errorText={"There was an error, please try again."}
            stretch={true}
            i18nStrings={{ errorIconAriaLabel: 'Error' }}
          >
            <Box variant="p">
              <input ref={ref} type="file" accept={".csv"} style={{display: "none"}} onChange={handleFileChange} />
              <Button onClick={handleClick}>Choose file</Button>
            </Box>
          </FormField>
          {fileToUpload &&
            <>
              <TextContent>
                <br/>
                <Icon name="status-positive" variant="success" /> 
                <strong> File: {fileToUpload.name}</strong>
                <br/>
                {/* <small>Size: {fileToUpload.size} B</small> */}
              </TextContent>
            </>
          }
        </Container>
      </Form>
    </ContentLayout>
    );
};
