import axios from "axios";
import keyIndex from 'react-key-index';


async function FetchMarkerHistory(device_id, setMarkerHistory) {
  await axios
  .get(
    "https://api.asset-tracking.aandraca.people.aws.dev/tracker?device_id=" + device_id //remove the underscore in device_id
  ).then(async function(response) {
    console.log("FETCHING TRACKER HISTORY...");
    console.log("RESPONSE :", response.data)
    setMarkerHistory(JSON.parse(response.data.body));
  }).catch(function(error) {
    console.log(error);
  })
}

export default FetchMarkerHistory;
