import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import {
  AppLayout,
  BreadcrumbGroup,
  TopNavigation,
} from "@cloudscape-design/components";

import "./styles.css";
import '@aws-amplify/ui-react/styles.css';

import { Navigation } from "./components";
import HomeView from "./pages/HomeView";
import UploadGeofences from "./pages/UploadGeofences";
import MetricsView from "./pages/MetricsView";


function App() {
  const [ isNavigationOpen , setIsNavigationOpen ] = useState(false);
  return (
    <>
      <TopNavigation
        identity={{
          // logo: { src: <AiFillCar /> },
          title: `Asset tracking - Amazon Location Service`,
          href: '/',
        }}
        i18nStrings={{
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'All'
        }}
      />
      {/* <Button onClick={signOut}>Sign out</Button> */}
      <AppLayout
        headerSelector="[data-id='top-navigation]"
        navigation={<Navigation />}
        // breadcrumbs={<BreadcrumbGroup />}
        navigationOpen={isNavigationOpen}
        onNavigationChange={() => setIsNavigationOpen(!isNavigationOpen)}
        content={
          <Router>
            <Routes>
              <Route path="/*" element={<HomeView />}/>
              <Route path="/upload-geofences/" element={<UploadGeofences />} />
              <Route path="/metrics/" element={<MetricsView />} />
            </Routes>
          </Router>
        }
      />
    </>
  );
}

export default App;