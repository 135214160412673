import axios from "axios";
import keyIndex from 'react-key-index';


async function FetchMarkersList(setMarkersList) {
  await axios
  .get(
    "https://api.asset-tracking.aandraca.people.aws.dev/tracker"
  ).then(async function(response) {
    console.log("FETCHING TRACKERS LIST...");
    console.log("RESPONSE: ", response.data)
    setMarkersList(JSON.parse(response.data.body));
  }).catch(function(error) {
    console.log(error);
  })
}

export default FetchMarkersList;