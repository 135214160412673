import React, { useState } from "react";

import {
  Autocomplete,
  Divider,
  Flex,
  HighlightMatch,
  View,
} from "@aws-amplify/ui-react";


const renderOption = (option, value) => {
  return (
    <HighlightMatch query={value}>
      {option?.device_id}
    </HighlightMatch>
  );
};

export function MarkerSearchBar(props) {
  const options = props.options;
  const [value, setValue] = useState('');

  const onChange = (option) => {
    setValue(option?.device_id);
  };

  // It is your responsibility to set up onSelect
  const onSelect = (option) => {
    const { label } = option;
    setValue(label);
    props.setSelectedMarker(option);
    props.fetchMarkerHistory(option.device_id, props.setMarkerHistory);
  };

  // It is your responsibility to set up onClear
  const onClear = () => {
    setValue('');
  };



  const optionFilter = (option, value) => {
    // filter options against device_id
    return option?.device_id?.includes(value);
  };

  // console.log("OPTIONS : ", options)
  
  return (
    <Flex flex={1}>
      <Autocomplete
        label="Marker search bar"
        placeholder="Search for a marker"
        size="small"
        options={options}
        optionFilter={optionFilter}
        renderOption={renderOption}
        value={value}
        onChange={onChange}
        onClear={onClear}
        onSelect={onSelect}
        menuSlots={{
          Header: (
            <View padding="xxxs">
              <View>Devices ID:</View>
              <Divider />
            </View>
          ),
          Empty: <View>No results found :(</View>,
        }}
      />
    </Flex>
  );
}